<template>
    <div class="doctorMeets_container global_full_screen">
        <div class="doctorMeets_doctor_info">
            <div class="doctor_info_block">
                <div><span class="doctor_info_key">姓名：</span><span class="doctor_info_value">{{data.name}}</span></div>
                <div><span class="doctor_info_key">医院：</span><span class="doctor_info_value">{{data.hospital || "暂无"}}</span></div>
            </div>
            <div class="doctor_info_link" @click="goToDoctorInfo">个人信息
                <el-icon  >
                    <ArrowRightBold />
                </el-icon>
            </div>
        </div>
        <div class="doctorMeets_meet_main global_meeting_padding">
            <div class="doctorMeets_meet_title">
                <img :src="require('@/assets/images/icon/list.png')" alt="" />
                <span>会议列表（{{meetingCount}}）</span>
            </div>
            <div class="doctorMeets_meet_list">
                <listScroll :url="$urls.admin.doctorMeetingList" :select="search" @receiveResponse="receiveResponse">
                    <template  v-slot:listItem="{citems}">
                        <div class="doctorMeets_items">
                                <div class="doctorMeets_items_row">
                                    <div class="doctorMeets_item_information_block doctorMeets_item_information_block_detail">
                                        <div class="doctorMeets_infomation_item">
                                            <div class="meet_info_key">会议日期</div>
                                            <div class="meet_info_value">{{citems.start_time.substr(0,16)}}</div>
                                        </div>
                                        <div class="doctorMeets_infomation_item">
                                            <div class="meet_info_key">会议主题</div>
                                            <div class="meet_info_value">{{citems.name}}</div>
                                        </div>
                                    </div>
                                    <div class="doctorMeets_item_information_block" :class="citems.state != 2 ?'meet_state_wait':'meet_state_done'">
                                        <div class="doctorMeets_infomation_item"></div>
                                        <div class="doctorMeets_infomation_item">
                                            <!-- {{$config.categary.meeting[citems.status]}} -->
                                            {{citems.is_test?$config.categary.meeting[0]:$tools.getMeetStatus(citems,1)}}
                                            <el-icon>
                                                <ArrowRightBold />
                                            </el-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="doctorMeets_item_button_group">
                                    <customButton class="doctorMeets_item_button" v-if="citems.status < 2" @click="goToPreveiw(citems)">
                                        <div>
                                            <span>简历页预览</span>
                                        </div>
                                    </customButton>
                                    <customButton class="doctorMeets_item_button" v-if="citems.status < 2" @click="goToClassPreveiw(citems)">
                                        <div>
                                            <span>课件修改</span>
                                        </div>
                                    </customButton>
                                    <customButton class="doctorMeets_item_button" v-if="citems.status === 0 || citems.is_test == 1" @click="goToMeetingInfo(citems)">
                                        <div>
                                            <span>会议修改</span>
                                        </div>
                                    </customButton>
                                    <customButton class="doctorMeets_item_button" v-if="citems.status < 2" @click="goToMeeting(citems)">
                                        <div>
                                            <span>进入会议</span>
                                        </div>
                                    </customButton>
                                    <customButton class="doctorMeets_item_button"  v-if="citems.status == 2" @click="watchMeeting(citems)">
                                        <div>
                                            <span>查看会议</span>
                                        </div>
                                    </customButton>
                                    <customButton class="doctorMeets_item_button" v-if="citems.status == 2 && citems.audit_status == $config.categary.audit.auditing" @click="returnReRcord(citems)">
                                        <div>
                                            <span>退回重新录制</span>
                                        </div>
                                    </customButton>
                                    <customButton class="doctorMeets_item_button" size="submit" v-if="citems.status == 2 && citems.audit_status == $config.categary.audit.auditing" @click="showSubmitPoup(citems)">
                                        <div>
                                            <span>确认提交</span>
                                        </div>
                                    </customButton>
                                    <customButton class="doctorMeets_item_button" v-if="citems.audit_status == $config.categary.audit.fail" @click="remindDoctorReRcord(citems)">
                                        <div>
                                            <span>重新录制提醒</span>
                                        </div>
                                    </customButton>
                                    <customButton class="doctorMeets_item_button" v-if="citems.audit_status == $config.categary.audit.success && !citems.sign_name" @click="remindDoctor(citems)">
                                        <div>
                                            <span>协议签署提醒</span>
                                        </div>
                                    </customButton>
                                </div>
                                <div class="doctorMeets_error_message" v-if="isShowMessage(citems) && citems.feed_content">{{citems.feed_content}}</div>
                        </div>
                    </template>
                </listScroll>
            </div>
            <div class="doctorMeets_button_group">
                <customButton class="doctorMeets_button_item" size="small" @click="goToCreateMeeting">
                    <div class="doctorMeets_button_info">
                        <img :src="require('@/assets/images/icon/meet_create.png')" alt="" />
                        <span>新建会议</span>
                    </div>
                </customButton>
            </div>
        </div>
        <popup :cfg="popupCfg" v-if="isshowSubmitPoup">
          <div class="doctorMeets_tip_content">
              <div class="doctorMeets_tip_content_text">
                {{getSubmitText()}}
              </div>
              <div class="doctorMeets_add_page">
                  <customButton class="doctorMeets_help_button"  @click="doSubmit()">
                      确认提交
                  </customButton>
                  <customButton class="doctorMeets_help_button" @click="hideSubmitPoup()">
                      取消
                  </customButton>
              </div>
          </div>
      </popup>
    </div>
</template>
<script>
import {ElIcon} from 'element-plus';
import { ArrowRightBold } from '@element-plus/icons'
import customButton from '@/components/unit/customButton'
import listScroll from '@/components/unit/listScroll'
import popup  from '@/components/unit/popup'
import store  from '@/store'
export default {
    name:"doctorMeets",
    components:{
        customButton,
        listScroll,
        ArrowRightBold,
        ElIcon,
        popup
    },
    data(){
        return {
            submit:{
                32:"请确认是否已查看会议，并满足项目要求（如音质清晰，内容完整等）",
                default:"请确认是否已查看会议，并满足项目要求（如音质清晰，包含病例内容等）"
            },
            popupCfg:{
                title:'',
                content:"",
                button:[]
            },
            search:{
                id:0
            },
            submitAuditTips:{
                25:"退回成功,请联系专家重新录制",
                15:"提交成功"
            },
            submitData:{},
            meetingCount:0,
            isshowSubmitPoup:false,
            data:{},
            loading:false,
            hasPage:false,
        }
    },
    created(){
        document.getElementsByTagName("title")[0].innerHTML = "会议状态"
        this.search.id = this.$route.query.id;
        this.search.project_id = this.$tools.getStorage("projectId",sessionStorage)
        this.loadData()
        this.hasDoctorPage()
    },
    methods: {
        getSubmitText(){
            let projectId = this.$tools.getStorage("projectId",sessionStorage);
            return this.submit[projectId] || this.submit.default
            
        },
        loadData(){
            let url = this.$tools.getURL(this.$urls.admin.doctorInfo,this.search)
            this.$axios.get(url).then(res=>{
                this.data = res.data;
            }).catch(err=>{})
        },
        receiveResponse(response){
            console.log(response)
            this.meetingCount = response.count;
        },

        goToPreveiw(item){
            this.$router.push({
                path:"/doctorPreview",
                query:{id:item.id}
            })
        },
        goToClassPreveiw(item){
            let url = item.meeting_url.split("#")[1];
            this.$router.push({
                path:url,
                query:{
                    edit_pref:"admin",
                    is_test:1,
                    doctor_id:item.doctor_id,
                    role:this.$config.categary.role.doctor
                }
            })
        },
        goToDoctorInfo(){
            this.$router.push({
                path:"/doctorInfo",
                query:this.search
            })
        },
        hasDoctorPage(){
            let adminInfo = store.state.common.adminInfo;
            let doctorID = this.search.id;
            let url = this.$tools.getURL(this.$urls.doctor.hasPage,{
                id:doctorID,
                phone:adminInfo.phone,
                project_id:this.$tools.getStorage("projectId",sessionStorage)
            })
            this.$axios.get(url).then(res=>{
                this.hasPage = Boolean(res.data)
                console.log(this.hasPage)
            }).catch(err=>{})
        },
        verifyData(){
            let verifyList = [
                "bank",
                "bank_number",
                "id_card_back",
                "id_card_front",
                "id_card_number",
                "phone"
            ]
            let resumeList = ["resume","resume_academic","profile"];
            for(let index in verifyList){
                let key = verifyList[index]
                if(!this.data[key]){
                    return false;
                }
            }
            for(let index in resumeList){
                let key = resumeList[index]
                if(this.hasPage && !this.data[key]){
                    return false;
                }
            }
            return true;
        },
        isShowMessage(data){
            return [10,20,30].includes(data.audit_status)
        },
        remindDoctor(data){
            if(this.loading) return ;
            this.loading = true;
            let url = this.$tools.getURL(this.$urls.admin.meetSign,{id:data.id})
            this.$axios.get(url).then(res=>{
                setTimeout(()=>{ this.loading = false; },1000)
                this.$tips.success({text:"提醒成功"})
            }).catch(err=>{
                setTimeout(()=>{ this.loading = false; },1000)
            })
        },
        remindDoctorReRcord(data){
            // todo.....
            if(this.loading) return ;
            this.loading = true;
            let url = this.$tools.getURL(this.$urls.admin.meetError,{id:data.id})
            this.$axios.get(url).then(res=>{
                setTimeout(()=>{ this.loading = false; },1000)
                this.$tips.success({text:"提醒成功"})
            }).catch(err=>{
                setTimeout(()=>{ this.loading = false; },1000)
            })
        },
        returnReRcord(data){
            let tempData = {
                id:data.id,
                status:this.$config.categary.audit.rerecord
            }
            this.doAuditStatus(tempData,data)
        },
        showSubmitPoup(data){
            this.submitData = data
            this.isshowSubmitPoup = true;
        },
        hideSubmitPoup(){
            this.submitData = {}
            this.isshowSubmitPoup = false;
        },
        doSubmit(){
            let tempData = {
                id:this.submitData.id,
                status:this.$config.categary.audit.submitAudit
            }
            this.isshowSubmitPoup = false;
            this.doAuditStatus(tempData,this.submitData)
        },
        doAuditStatus(data,item){
            // console.log(data,item)
            // return
            if(this.loading) return ;
            this.loading = true;
            let url = this.$urls.admin.auditStatus
            // this.$tips.success({text:`${this.submitAuditTips[data.status]}`})
            this.$axios.patch(url,data).then(res=>{
                setTimeout(()=>{ this.loading = false; },1000)
                item.audit_status = data.status
                if(data.status === 25){
                    item.status = 1
                }
                this.loadData()
                this.$tips.success({text:`${this.submitAuditTips[data.status]}`})
            }).catch(err=>{
                setTimeout(()=>{ this.loading = false; },1000)
            })
        },
        goToMeetingInfo(item){
            this.$router.push({
                path:"/createMeeting",
                query:{
                    id:this.search.id,
                    meetId:item.id
                }
            })
        },
        goToMeeting(data){
            let url = data.meeting_url.split("#")[1];
            this.$router.push({
                path:url,
                query:{
                    role:this.$config.categary.role.guest
                }
            })
        },
        watchMeeting(data){
            let url = data.meeting_url.split("#")[1];
            this.$router.push({
                path:url,
                query:{
                    role:this.$config.categary.role.doctor,
                    admin:1,
                    watch:1,
                    openid:data.openid,
                }
            })
        },
        goToCreateMeeting(){
            if(!this.verifyData()) {
                this.$tips.error({text:"请先完善专家个人信息"})
                return;
            }
            this.$router.push({
                path:"/createMeeting",
                query:this.search
            })
        }
    },
}
</script>
<style>
    .doctorMeets_container{
        background:#ececec;
        display: flex;
        flex-direction: column;
    }
    .doctorMeets_error_message{
        color:red;
        margin-top:4vw;
    }
    .doctorMeets_doctor_info{
        flex:22.93vw 0 0;
        background:#fff;
        margin-bottom:2vw;
        padding:6vw 4vw;
        display: flex;
        justify-content: space-between;
    }
    .doctor_info_key{
        color: #0000c9;
        font-size: 4vw;
        font-weight: 400;
    }
    .doctor_info_value{
        font-size: 4vw;
        font-weight: 400;
    }
    .doctor_info_block{
        flex: 1 0 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .doctor_info_link{
        font-size: 4vw;
        flex: 20vw 0 0;
        display: flex;
        align-items: flex-end;
        color: #0000c9;
        white-space: nowrap;
    }
    .doctorMeets_container .el-icon{
        color: #777777;
        font-size: 4vw;
    }
    .doctorMeets_meet_main{
        padding: 0 4vw 4vw 4vw;
        flex:1 0 0;
        background:#fff;
        display: flex;
        flex-direction: column;
    }
    .doctorMeets_meet_title{
        display: flex;
        align-items: center;
        white-space: nowrap;
        color: #0000c9;
        font-weight: 600;
        padding:4.8vw 0;
        flex: 13.6vw 0 0;
        border-bottom:#ccc 1px solid;
    }
    .doctorMeets_meet_title img{
        height: 5.1vw;
        width:auto;
        margin-right:1vw;
    }
    .doctorMeets_meet_list{
        flex: 1 0 0;
    }
    .doctorMeets_items{
        /* height: 30vw; */
        padding:3vw 0;
        /* display: flex;
        flex-direction: column;
        justify-content: space-between; */
        border-bottom:#ccc 1px solid;
    }
    .doctorMeets_items_row{
        flex: 1 0 0;
        display: flex;
        padding-bottom: 4vw;
    }
    .doctorMeets_item_information_block_detail{
        flex:1 0 0;
    }
    .doctorMeets_item_information_block{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .doctorMeets_item_state{
        height: 100%;
        display: flex;
        align-items: flex-end;
    }
    .doctorMeets_item_button_group{
        flex: 7.6vw 0 0;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
    .doctorMeets_item_button{
        margin-left: 5vw;
        width: auto;
        font-size: 3.6vw;
        padding: 0 4vw;
        margin-bottom: 1vw;
    }
    .doctorMeets_button_group{
        flex:9.4vw 0 0;
        width:100%;
    }
    .doctorMeets_button_item{
        height:100%;
        width:100%;
    }
    .doctorMeets_button_info{
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
    .doctorMeets_button_info img{
        height: 5.1vw;
        width:auto;
        margin-right:1vw;
    }
    .meet_info_key{
        color: #9b9b9b;
        font-size: 4vw;
        margin-right: 4vw;
        font-weight: 400;
        white-space: nowrap
    }
    .meet_info_value{
        flex: 1 0 0;
        font-size: 4vw;
        font-weight: 400;
        word-break: break-all;
    }
    .meet_state_wait{
        min-width:20vw;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .meet_state_done{
        min-width:20vw;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        color: #9b9b9b;
    }
    .doctorMeets_infomation_item{
        display: flex;
        padding: 2vw 0;
    }
    
.doctorMeets_tip_content{
    /* display: flex; */
    /* flex-direction: column; */
    padding:4vw 0;
}

.doctorMeets_tip_content_text{
    display: flex;
    align-items: center;
    height: 16vw;;
    padding: 0 5vw;
}

.doctorMeets_add_page{
    display: flex;
    justify-content: center;
}
.doctorMeets_add_page .doctorMeets_help_button{
    flex: 30% 0 0;
    padding: 2vw 0;
}
.doctorMeets_add_page .doctorMeets_help_button:first-child{
    margin-right:5vw;
}
</style>